import Box from "@mui/material/Box";
import { Button, Divider, Drawer, Stack, Typography } from "@mui/material";
import * as React from "react";
import LibraryAddCheckIcon from "@mui/icons-material/LibraryAddCheck";
import RamenDiningIcon from "@mui/icons-material/RamenDining";

interface Props {
  openHistoryDrawer: boolean;
  toggleDrawer: (open: boolean) => void;
  orderDetails: any;
}
const OrderHistoryDrawer = ({
  openHistoryDrawer,
  toggleDrawer,
  orderDetails,
}: Props) => {
  return (
    <Drawer
      sx={{ zIndex: "999999" }}
      anchor={"right"}
      open={openHistoryDrawer}
      onClose={() => toggleDrawer(false)}
    >
      <Box
        sx={{
          width: 500,
          px: 3,
          py: 4,
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <Typography
            variant="h4"
            fontWeight={600}
            sx={{
              color: "#161546",
            }}
          >
            Items History
          </Typography>
          <Button
            variant="contained"
            onClick={() => toggleDrawer(false)}
            sx={{
              borderRadius: 1,
              backgroundColor: "#161546",
            }}
          >
            Close
          </Button>
        </Stack>
        <Divider />
        <Box mt={1} mb={2}>
          <Stack direction="row" alignItems="center" gap={1} mb={1}>
            <RamenDiningIcon />
            <Typography style={{ fontWeight: 600 }}> Menu Items</Typography>
          </Stack>

          {orderDetails?.cart_items?.map((cart: any, index: number) => {
            return (
              <React.Fragment key={index}>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  spacing={0.5}
                >
                  <Stack direction="row" spacing={2}>
                    <Typography variant="body2" color="black">
                      {cart.quantity}X
                    </Typography>
                    <Typography variant="body2" fontSize="15px">
                      {cart.dineInItem_name}
                    </Typography>
                  </Stack>
                  <Typography variant="body2" fontSize="15px">
                    {(cart.dineInItem_price * cart.quantity).toFixed(2)}
                  </Typography>
                </Stack>
                <Divider />

                {cart?.extras?.map((extra: any, extraIndex: number) => {
                  return (
                    <Stack
                      key={extraIndex}
                      direction="row"
                      justifyContent="space-between"
                      marginLeft="35px"
                    >
                      <Typography variant="body2" fontSize="12px">
                        {extra.name}
                      </Typography>
                      <Typography variant="body2" fontSize="12px">
                        {extra.price}
                      </Typography>
                    </Stack>
                  );
                })}

                {cart?.selections && (
                  <Stack
                    direction="row"
                    alignItems="center"
                    gap={1}
                    mb={1}
                    mt={1}
                    key={cart.id} // Assuming cart.id is unique for each cart
                  >
                    <LibraryAddCheckIcon />
                    <Typography style={{ fontWeight: 600 }}>
                      Selections
                    </Typography>
                  </Stack>
                )}

                {cart?.selections?.map((select: any, selectIndex: number) => {
                  return (
                    <React.Fragment key={selectIndex}>
                      <Typography variant="body2" fontSize="15px">
                        {select.name}
                      </Typography>
                      {select?.selection_items.map(
                        (items: any, itemIndex: number) => {
                          return (
                            <Stack
                              key={itemIndex}
                              direction="row"
                              justifyContent="space-between"
                              spacing={0.5}
                            >
                              <Stack direction="row" spacing={2}>
                                <Typography
                                  variant="body2"
                                  fontSize="12px"
                                  fontWeight="200px"
                                >
                                  {items.name}
                                </Typography>
                              </Stack>
                              <Typography variant="body2" fontSize="12px">
                                {items.price}
                              </Typography>
                            </Stack>
                          );
                        }
                      )}
                    </React.Fragment>
                  );
                })}
              </React.Fragment>
            );
          })}
        </Box>
      </Box>
    </Drawer>
  );
};

export default OrderHistoryDrawer;
