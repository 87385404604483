import * as React from "react";
import Box from "@mui/material/Box";
import {
  DataGrid,
  GridColDef,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
  GridExperimentalFeatures,
} from "@mui/x-data-grid";
import { useState } from "react";
import { Button, Chip } from "@mui/material";
import OrderHistoryDrawer from "../orderDetailsDrawer";

type Props = {
  orderRow: { [key: string]: any }[] | [];
};
export default function ReportTable({ orderRow }: Props) {
  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarQuickFilter />
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarExport printOptions={{ disableToolbarButton: true }} />
      </GridToolbarContainer>
    );
  };

  function getCreatedDate(row: any) {
    return row?.created_at
      ? new Date(row?.created_at).toLocaleDateString()
      : new Date(row?.updated_at).toLocaleDateString();
  }
  function getCreatedTime(row: any) {
    return row?.created_at
      ? new Date(row?.created_at).toLocaleTimeString()
      : new Date(row?.updated_at).toLocaleTimeString();
  }
  const [openHistoryDrawer, setHistoryDrawer] = useState(false);
  const toggleDrawer = (open: boolean) => {
    setHistoryDrawer(open);
    setSelectedOrderDetails(null);
  };
  const [selectedOrderDetails, setSelectedOrderDetails] = useState<any>({});
  const onShowHistoryClick = (orderDetails: any) => {
    toggleDrawer(true);
    setSelectedOrderDetails(orderDetails);
  };
  const columnsForOrderList: GridColDef[] = [
    {
      field: "created_at",
      headerName: "Created Date",
      width: 300,
      // minWidth:200,
      editable: false,
      sortable: false,
      valueGetter: (_, row) => getCreatedDate(row),
    },
    {
      field: "updated_at",
      headerName: "Delivered Time",
      width: 300,
      // minWidth:200,
      editable: false,
      sortable: false,
      valueGetter: (_, row) => getCreatedTime(row),
    },
    {
      field: "guest_name",
      headerName: "Guest Name",
      width: 250,
      // minWidth:200,
      editable: false,
      sortable: false,
    },
    {
      field: "room_id",
      headerName: "Room Number",
      width: 250,
      // minWidth:200,
      editable: false,
      sortable: false,
    },
    {
      field: "status",
      headerName: "Status",
      sortable: false,
      width: 250,
      renderCell: (params) => (
        <Chip
          label={params.row.status}
          color={(() => {
            switch (params.row.status) {
              case "DELIVERED":
                return "success";
              case "CANCELLED":
                return "error";
              default:
                return "primary";
            }
          })()}
        />
      ),
    },
    {
      field: "total",
      headerName: "Amount",
      // width: 200,
      minWidth: 200,
      editable: false,
      sortable: false,
      renderCell: (params) => (
        <Button
          className="p-0 guestList-tableP"
          sx={{ padding: 0 }}
          onClick={() => onShowHistoryClick(params.row)}
        >
          {params.row.total}
        </Button>
      ),
    },
  ];

  return (
    <Box sx={{ height: 765, width: "100%" }}>
      <DataGrid
        rows={orderRow || []}
        columns={columnsForOrderList}
        // pageSize={9}
        // rowsPerPageOptions={[9]}
        density="comfortable"
        checkboxSelection
        // disableSelectionOnClick
        experimentalFeatures={
          { newEditingApi: true } as Partial<GridExperimentalFeatures>
        }
        slots={{ toolbar: CustomToolbar }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
      />
      <OrderHistoryDrawer
        openHistoryDrawer={openHistoryDrawer}
        toggleDrawer={toggleDrawer}
        orderDetails={selectedOrderDetails}
      />
    </Box>
  );
}
